<!-- Facturas de la remesa seleccionada -->

<template>
  <div class="cobros_pagos_frasRemesa"> 
    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <template v-slot:activator="{on}">
        <v-btn 
          v-on="records? on : ''"
          v-on:click="records? ini_component() : errorRecords()"
          v-bind="$cfg.btra_cfg.standard"
          :disabled="disabled"
          title="Facturas de la remesa seleccionada">
            <v-icon>{{'mdi-receipt'}}</v-icon>
        </v-btn>       
      </template>

      <div class="centrado">
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>                  
          </div>

          <!-- Contenido -->
          <div class="columna" style="width:500px"> 
           
            
              <!-- Grid -->
              <base_Fgrid
                :padre="stName" 
                :Entorno="Entorno"  
                height="300px"        
                :items-per-page=-1 
                hide-default-footer 
                fixed-header     
                dense>
          
              </base_Fgrid>
          
          </div>
        </v-sheet> 
      </div>
    </v-dialog>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
      
  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid },
    props: {
      padre: { type:String, default: '' }, 
      records: { type:[Array, Object], default:null },
      disabled: { type:Boolean, default:false },
      componenteTipo: { type:String, default:'F' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {}
        },
        api:'',
        stName:'stFcobros_pagos_frasRemesa',
        headers: {},

        dialog:false
      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "Facturas"; 
        this.Entorno.grid.autoload= false;

        this.headers = {
          header: [
            { text: "Factura", value: "fra_doc", width: "25%" },
            { text: "Fecha", value: "fra_fh", width: "30%" , filtro:'fecha'},
            { text: "Expte", value: "control", width: "15%" },
            { text: "Importe", value: "imp", width: "30%" }
         ],

        };

        this.Entorno.grid.headers = this.set_headers();
      },


      // obtengo los registros a mostrar
      ini_component() {       
        // guardo records del Grid todos los arrays recibidos, en 'recordsDetalle'
        this.$store.commit(this.stName + '/update_records', this.records);
        this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: this.records }); 
      },


      //
      errorRecords() {
        this.$root.$alert.open('Debe seleccionar una Remesa', 'error');
      }

    }
  };
</script>
